<template>
  <portrait-grid>
    <div v-for="(tutor, i) in tutorList" :key="tutor.id">
      <test-anchor
        v-if="tutor.tutor !== undefined"
        :id="`tutor-card-${i}`"
        :data="{title: tutor.tutor.title, slug: tutor.tutor.slug}"
      >
        <tutor-card
          :tutor="tutor.tutor"
          :display-bio="true"
          :display-mentor-focus-of-study="true"
          :image-width="imageWidth"
          class="mb-4"
        />
      </test-anchor>
      <tutor-card
        v-else
        :tutor="undefined"
        :display-bio="true"
        :display-mentor-focus-of-study="true"
        :image-width="imageWidth"
        class="mb-4"
      />
    </div>
  </portrait-grid>
</template>

<script lang="ts">
import {PropType, computed} from 'vue';
import PortraitGrid from '../../../core/compositions/PortraitGrid.vue';
import TutorCard from '../../tutors/TutorCard.vue';
import TestAnchor from '../../../core/test/TestAnchor.vue';
import {Tutor} from '../../../../backend/tutor/tutor-types';
import {ImgixImageWidth} from '../../../core/imgix-image/types';
import {range} from '../../../../utils/array';

export default {
  components: {
    PortraitGrid,
    TutorCard,
    TestAnchor
  },
  props: {
    tutors: {type: Array as PropType<ReadonlyArray<Tutor>>, required: true}
  },
  setup(props) {
    const imageWidth: Readonly<ImgixImageWidth> = {narrow: 400};

    const tutorList = computed(() => {
      if (props.tutors.length === 0) {
        return range(10).map(i => {
          return {
            id: i,
            tutor: undefined
          };
        });
      }

      return props.tutors.map(tutor => {
        return {
          id: tutor.id,
          tutor
        };
      });
    });

    return {
      tutorList,
      imageWidth
    };
  }
};
</script>
