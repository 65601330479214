<template>
  <div
    id="modal-dialog"
    class="fixed left-1/2 top-1/2 -translate-y-1/2 -translate-x-1/2 flex justify-center items-center"
    :style="style"
  >
    <div
      class="w-full h-full max-h-[90vh] overflow-auto py-fl-sm-2xl px-fl-md-xl bg-darkest rounded-xl"
    >
      <div class="w-full h-full">
        <slot />

        <modal-dialog-footer :config="config" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {computed, defineComponent, onBeforeUnmount, onMounted, PropType} from 'vue';
import ModalDialogFooter from './partials/ModalDialogFooter.vue';
import {ModalDialogConfig} from '../../vue-composition/modal-dialog/types';
import {faXmark} from '@fortawesome/pro-light-svg-icons/faXmark';
import {useStandardPageArea} from '../../core/column-layout/utils';
import {useDeviceType} from '../../vue-composition/device-type/device-type';
import {useColumnLayout} from '../../vue-composition/column-layout/column-layout';

/**
 * A modal dialog with an OK button and an optional Cancel button.
 *
 * The modal dialog component is essentially the "frame" in which you
 * put the actual dialog content. To use the modal dialog, begin by
 * creating a new component that specialises <modal-dialog>, e.g.,
 *
 * -- MyDialog.vue --
 *
 * <template>
 *    <modal-dialog :config="config">
 *      ...dialog elements here...
 *    </modal-dialog>
 * </template>
 *
 * Then, add the modal dialog Vue composition to the file from where you
 * want to show the dialog:
 *
 *    import MyDialog from ...;
 *    import {useModalDialog} from ...;
 *
 *    ...
 *
 *    const modalDialog = useModalDialog(
 *      () => { ...respond to OK clicked... },
 *      () => { ...respond to cancel clicked... },
 *      MyDialog
 *    );
 *
 *    ...
 *
 *    modalDialog.show({ ...props to MyDialog... }); // Displays the modal dialog
 *
 */
export default defineComponent({
  components: {
    ModalDialogFooter
  },
  props: {
    config: {type: Object as PropType<Readonly<ModalDialogConfig>>, required: true}
  },
  setup(props) {
    // Cancel the dialog when Escape key is pressed.
    const handler = (event: any) => {
      if (event.key === 'Escape') {
        props.config._onCancel();
      }
    };
    onMounted(() => {
      document.addEventListener('keydown', handler);
    });
    onBeforeUnmount(() => {
      document.removeEventListener('keydown', handler);
    });

    const iconXmark = computed(() => faXmark);

    const deviceType = useDeviceType();
    const spa = useStandardPageArea(deviceType, 'narrow');
    const columnLayout = useColumnLayout(deviceType);
    const style = computed(() => {
      const c = columnLayout.config.value.columnWidth;
      const g = columnLayout.config.value.gutterWidth;
      const n = spa.area.value.span;
      const width = n * c + (n - 1) * g;
      return `width:${width}px`;
    });

    return {
      iconXmark,
      style
    };
  }
});
</script>
