<template>
  <content-column v-if="level !== undefined" :width="'narrow'">
    <variants>
      <!-- Narrow version -->
      <template #narrow>
        <div class="bg-darkest p-fl-lg flex flex-col gap-fl-lg" :style="rounded">
          <div class="text-fl-xl text-white font-semibold text-center">{{ heading }}</div>
          <div class="flex justify-center">
            <players-path-level-number
              :level="level"
              :completed="levelComplete"
              class="max-w-[130px]"
            />
          </div>
          <players-path-level-progress-bar :level="level" />
          <div class="flex justify-center">
            <standard-button :url="level.url" :label="`Continue`" />
          </div>
        </div>
      </template>

      <!-- Wide version -->
      <template #sm+>
        <div class="bg-darkest p-fl-lg grid grid-cols-[0.2fr_0.8fr] gap-fl-lg" :style="rounded">
          <div>
            <players-path-level-number :level="level" :completed="levelComplete" />
          </div>
          <div class="flex flex-col justify-between gap-fl-sm">
            <div class="text-fl-2xl text-white font-semibold">{{ heading }}</div>
            <div class="h-fl-2xs flex flex-col justify-center">
              <players-path-level-progress-bar :level="level" />
            </div>
            <repel>
              <div class="flex text-mid items-end">
                {{ completion }}
              </div>
              <link-button
                :color="'white'"
                :underline="true"
                :url="level.url"
                :label="`To ${level.title}`"
              />
            </repel>
          </div>
        </div>
      </template>
    </variants>
  </content-column>
</template>

<script lang="ts">
import {ref, onMounted, PropType, computed} from 'vue';
import Variants from '../../../core/column-layout/Variants.vue';
import ContentColumn from '../../../core/compositions/ContentColumn.vue';
import StandardButton from '../../../core/button/StandardButton.vue';
import LinkButton from '../../../core/button/LinkButton.vue';
import Repel from '../../../core/compositions/Repel.vue';
import PlayersPathLevelNumber from './PlayersPathLevelNumber.vue';
import PlayersPathLevelProgressBar from './PlayersPathLevelProgressBar.vue';
import {useLoader} from '../../../vue-composition/loader/loader';
import {getPlayersPathContinueLevel} from '../../../../backend/content/players-path/players-path-query-utils';
import {PlayersPathLevel} from '../../../../backend/content/players-path/players-path-types';
import {useDeviceType} from '../../../vue-composition/device-type/device-type';
import {lerp} from '../../../../utils/math';

export default {
  components: {
    ContentColumn,
    Variants,
    StandardButton,
    LinkButton,
    Repel,
    PlayersPathLevelNumber,
    PlayersPathLevelProgressBar
  },
  props: {
    guest: {type: String, required: true},
    continueLevel: {type: Object as PropType<Readonly<PlayersPathLevel>>, default: undefined},
    heading: {type: String, default: 'Continue your work'}
  },
  setup(props) {
    const loader = useLoader();
    const level = ref<PlayersPathLevel | undefined>(undefined);

    onMounted(async () => {
      if (!props.guest) {
        if (props.continueLevel !== undefined) {
          level.value = props.continueLevel;
        } else {
          loader.setLoading(true);
          level.value = await getPlayersPathContinueLevel(props.guest);
          loader.setLoading(false);
        }
      }
    });

    const completion = computed(() => {
      if (level.value === undefined) {
        return '';
      }
      return `${level.value.numberOfCompletedPieces} of 3 Study Pieces Completed`;
    });

    const deviceType = useDeviceType();
    const textSize = computed(() => {
      const size = lerp(deviceType.interpolation.value, 75, 240);
      return `font-size:${size}px;`;
    });

    const rounded = computed(() => {
      const size = lerp(deviceType.interpolation.value, 1, 2);
      return `border-radius:${size}rem;`;
    });

    const levelComplete = computed(() => {
      if (level.value !== undefined) {
        return level.value?.completed;
      }
      return false;
    });

    return {
      level,
      deviceType,
      textSize,
      rounded,
      completion,
      levelComplete
    };
  }
};
</script>
