<template>
  <div>
    <page-section>
      <content-column>
        <standard-heading :size="2">Musician Profile</standard-heading>
      </content-column>
    </page-section>

    <page-section :margin-top="'sm'">
      <content-column :width="'text'">
        <p>
          We want you to have an amazing learning experience in the Academy, but it may feel
          overwhelming at first. To make it easier, please select one of the levels below. You can
          change your level in your profile at any time so don’t worry if you feel the level isn’t a
          perfect fit.
        </p>
      </content-column>
    </page-section>

    <page-section :margin-top="'md'">
      <content-column :width="'text'">
        <standard-heading :size="3">Beginner</standard-heading>
        <p>
          Select a beginner level if you’ve just started playing, you’re returning to bass after a
          break, or you just think you need to go back to basics to break bad habits.
        </p>
      </content-column>
    </page-section>
    <page-section :margin-top="'sm'">
      <content-column>
        <div class="grid grid-cols-[repeat(auto-fit,minmax(200px,1fr))] gap-fl-xs">
          <level-button
            v-for="lev in begLevels"
            :id="lev"
            :key="lev"
            :heading="headings[lev]"
            :text="texts[lev]"
            :color="colors[lev]"
            :selected="selected[lev]"
            :on-click="onSelect"
          />
        </div>
      </content-column>
    </page-section>

    <page-section :margin-top="'md'">
      <content-column :width="'text'">
        <standard-heading :size="3">Intermediate</standard-heading>
        <p>
          Select an intermediate level if you’re a proficient technical player, but want to extend
          your capabilities as musician, in specialist skills or across different styles.
        </p>
      </content-column>
    </page-section>
    <page-section :margin-top="'sm'">
      <content-column>
        <div class="grid grid-cols-[repeat(auto-fit,minmax(200px,1fr))] gap-fl-xs">
          <level-button
            v-for="lev in intLevels"
            :id="lev"
            :key="lev"
            :heading="headings[lev]"
            :text="texts[lev]"
            :color="colors[lev]"
            :selected="selected[lev]"
            :on-click="onSelect"
          />
        </div>
      </content-column>
    </page-section>

    <page-section :margin-top="'md'">
      <content-column :width="'text'">
        <standard-heading :size="3">Advanced</standard-heading>
        <p>
          Select an advanced level if you’re a proficient technical player and a proficient
          musician, but you want to continue to deepen and widen your skills.
        </p>
      </content-column>
    </page-section>
    <page-section :margin-top="'sm'">
      <content-column>
        <div class="grid grid-cols-[repeat(auto-fit,minmax(200px,1fr))] gap-fl-xs">
          <level-button
            v-for="lev in advLevels"
            :id="lev"
            :key="lev"
            :heading="headings[lev]"
            :text="texts[lev]"
            :color="colors[lev]"
            :selected="selected[lev]"
            :on-click="onSelect"
          />
        </div>
      </content-column>
    </page-section>
  </div>
</template>

<script lang="ts">
import {PropType, computed, defineComponent} from 'vue';

import PageSection from '../../../../../core/page/PageSection.vue';
import ContentColumn from '../../../../../core/compositions/ContentColumn.vue';
import StandardHeading from '../../../../../core/standard-heading/StandardHeading.vue';
import LevelButton from './partials/LevelButton.vue';
import {playersPathLevelSpotColor} from '../../../../../content-pages/players-path/utils';
import {range} from '../../../../../../utils/array';

export default defineComponent({
  components: {
    PageSection,
    ContentColumn,
    StandardHeading,
    LevelButton
  },
  props: {
    onSelect: {type: Function as PropType<(level: number) => void>, required: true},
    selectedLevel: {type: Number, default: undefined}
  },
  setup(props) {
    const colors = range(9).map(level => playersPathLevelSpotColor(level + 1));
    const headings = range(9).map(level => `Level ${level + 1}`);
    const texts = [
      'I’ve just started playing bass.',
      'I feel comfortable playing simple bass lines.',
      'I feel confident enough to learn and play easy songs.',
      'I can play in more than one style or genre and understand basic music theory.',
      'I’m adding specialist skills to my technique and have a developing grasp of harmony.',
      'I can learn most songs quickly, cover most techniques and create my own bass lines.',
      'I can play songs by memory or ear and improvise bass lines and basic solos.',
      'I can solo over chord progressions and quickly adapt to new musical situations.',
      'I’ve mastered music theory and I’m comfortable in almost all musical situations.'
    ];
    const selected = computed(() => {
      return range(9).map(level => level === props.selectedLevel);
    });

    const begLevels = [0, 1, 2];
    const intLevels = [3, 4, 5];
    const advLevels = [6, 7, 8];

    return {
      begLevels,
      intLevels,
      advLevels,
      colors,
      headings,
      texts,
      selected
    };
  }
});
</script>
