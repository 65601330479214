<template>
  <div v-if="!disabled">
    <content-column :width="width" :justify="justify">
      <test-anchor
        v-for="(ce, i) in pagination.items.value"
        :id="`content-entry-inline-card-${i}`"
        :key="ce.id"
        :data="{slug: ce.slug, title: ce.title}"
      >
        <content-entry-inline-card
          class="mb-3 sm:mb-10"
          :guest="guest"
          :content-entry="ce"
          :completed="completed[ce.id]"
          :progress="progress[ce.id]"
          :duration="duration[ce.id]"
          :unlocked="unlocked[ce.id]"
          :display-post-date="displayPostDate"
          :spot-color="spotColor"
          :bookmark="bookmark"
          :card-image-width="cardImageWidth"
          :imgix-params="imgixParams"
          :class="i !== pagination.items.value.length - 1 ? 'mb-fl-xl' : undefined"
        />
      </test-anchor>
    </content-column>
    <content-column v-if="pagination.more.value" :width="width">
      <standard-button :label="'Show More'" :on-click="pagination.onMore" />
    </content-column>
  </div>
  <div v-else>
    <content-column :width="width" :justify="justify">
      <content-entry-inline-card
        v-for="i in disabledItems"
        :key="i"
        :guest="guest"
        :bookmark="bookmark"
        :card-image-width="cardImageWidth"
        :imgix-params="imgixParams"
        class="mb-fl-xl"
      />
    </content-column>
  </div>
</template>

<script lang="ts">
import {computed, defineComponent, PropType} from 'vue';
import {ProgressMap} from '../../../../backend/progress/progress-types';
import {CompletedMap, DurationMap, UnlockedMap} from '../types';
import TestAnchor from '../../../core/test/TestAnchor.vue';
import ContentColumn from '../../../core/compositions/ContentColumn.vue';
import ContentEntryInlineCard from '../../representations/inline-card/ContentEntryInlineCard.vue';
import StandardButton from '../../../core/button/StandardButton.vue';
import {ColorConfig} from '../../../core/color-config';
import {BookmarkComposition} from '../../../vue-composition/bookmark/types';
import {PaginationComposition} from '../../../vue-composition/pagination/pagination';
import {StandardPageAreaJustify, StandardPageAreaWidth} from '../../../core/column-layout/utils';
import {ImgixImageWidth} from '../../../core/imgix-image/types';
import {range} from '../../../../utils/array';

export default defineComponent({
  components: {
    TestAnchor,
    ContentColumn,
    StandardButton,
    ContentEntryInlineCard
  },
  props: {
    guest: {type: String, required: true},
    width: {type: String as PropType<StandardPageAreaWidth>, default: undefined},
    justify: {type: String as PropType<StandardPageAreaJustify>, default: undefined},
    progress: {type: Object as PropType<Readonly<ProgressMap>>, default: () => ({})},
    completed: {type: Object as PropType<Readonly<CompletedMap>>, default: () => ({})},
    unlocked: {type: Object as PropType<Readonly<UnlockedMap>>, default: () => ({})},
    duration: {type: Object as PropType<Readonly<DurationMap>>, default: () => ({})},
    displayPostDate: {type: Boolean, default: false},
    spotColor: {type: Object as PropType<Readonly<ColorConfig> | undefined>, default: undefined},
    bookmark: {type: Object as PropType<Readonly<BookmarkComposition>>, required: true},
    pagination: {type: Object as PropType<Readonly<PaginationComposition>>, required: true},
    disabled: {type: Boolean, default: false},
    cardImageWidth: {type: Object as PropType<Readonly<ImgixImageWidth>>, required: true},
    imgixParams: {type: Object, default: undefined}
  },
  setup() {
    const disabledItems = computed(() => {
      return range(10);
    });

    return {
      disabledItems
    };
  }
});
</script>
