<template>
  <div class="flex flex-col gap-fl-md bg-darkest rounded-lg select-none px-fl-md-xl py-fl-sm-2xl">
    <div class="flex gap-fl-xl justify-between items-center">
      <div class="basis-1/5 flex flex-nowrap items-end">
        <!-- Pause/Play -->
        <song-player-play :song="song" :player="player" />
        <!-- Reset -->
        <song-player-reset :song="song" :player="player" :width="'w-1/3'" />
      </div>

      <!--  Bar/Beat -->
      <div class="basis-2/5 flex flex-nowrap gap-fl-xs-sm">
        <song-player-bar
          :player="player"
          :label="false"
          :text-size="'text-fl-10xl'"
          class="flex-auto basis-3/4"
        />
        <song-player-beat
          :player="player"
          :label="false"
          :text-size="'text-fl-10xl'"
          class="flex-auto basis-1/4"
        />
      </div>

      <!-- Loop range -->
      <div class="flex flex-nowrap gap-fl-xs">
        <song-player-range-selector
          :type="'start'"
          :disabled="disabled"
          :title="'Start Bar'"
          :range-selection="range"
          :padding="'p-fl-2xs-sm'"
          :gap="'gap-fl-3xs'"
          :font-size="fontSize"
          class="flex-1 basis-1/2"
        />
        <song-player-range-selector
          :type="'end'"
          :disabled="disabled"
          :title="'End Bar'"
          :range-selection="range"
          :padding="'p-fl-2xs-sm'"
          :gap="'gap-fl-3xs'"
          :font-size="fontSize"
          class="flex-1 basis-1/2"
        />
      </div>
    </div>

    <!-- Options -->
    <standard-divider class="border-dark" />
    <div class="flex justify-between items-center">
      <song-player-playback-speed-narrow
        :disabled="disabled"
        :variation-config="variationConfig"
        :font-size="'text-fl-xl'"
        :button-size="'text-fl-5xl'"
      />
      <song-player-repeat-narrow :disabled="disabled" :player="player" :font-size="'text-fl-xl'" />
      <song-player-stem-narrow
        :disabled="disabled"
        :variation-config="variationConfig"
        :font-size="'text-fl-xl'"
      />
      <song-player-precount-narrow
        :disabled="disabled"
        :variation-config="variationConfig"
        :font-size="'text-fl-xl'"
        :button-size="'text-fl-5xl'"
      />
    </div>
  </div>
</template>

<script lang="ts">
import {PropType, computed} from 'vue';
import StandardDivider from '../../../core/standard-divider/StandardDivider.vue';
import SongPlayerRangeSelector from '../partials/SongPlayerRangeSelector.vue';
import SongPlayerPlay from '../partials/SongPlayerPlay.vue';
import SongPlayerReset from '../partials/SongPlayerReset.vue';
import SongPlayerBar from '../partials/SongPlayerBar.vue';
import SongPlayerBeat from '../partials/SongPlayerBeat.vue';
import SongPlayerPlaybackSpeedNarrow from '../partials/SongPlayerPlaybackSpeedNarrow.vue';
import SongPlayerStemNarrow from '../partials/SongPlayerStemNarrow.vue';
import SongPlayerRepeatNarrow from '../partials/SongPlayerRepeatNarrow.vue';
import SongPlayerPrecountNarrow from '../partials/SongPlayerPrecountNarrow.vue';
import {VariationConfig} from '../internals/utils';
import {Song} from '../../../../backend/song/song-types';
import {LoopPlayer} from '../../../../utils/loop-player/loop-player';
import {useRangeSelection} from '../internals/bar-select';
import {useDeviceType} from '../../../vue-composition/device-type/device-type';
import {lerp} from '../../../../utils/math';
import {useLocalStorageFlag} from '../../../vue-composition/local-storage-flag/local-storage-flag';

export default {
  components: {
    StandardDivider,
    SongPlayerRangeSelector,
    SongPlayerPlay,
    SongPlayerReset,
    SongPlayerBar,
    SongPlayerBeat,
    SongPlayerPlaybackSpeedNarrow,
    SongPlayerStemNarrow,
    SongPlayerRepeatNarrow,
    SongPlayerPrecountNarrow
  },
  props: {
    song: {type: Object as PropType<Readonly<Song>>, required: true},
    player: {type: Object as PropType<Readonly<LoopPlayer>>, required: true},
    variationConfig: {type: Object as PropType<Readonly<VariationConfig>>, required: true},
    disabled: {type: Boolean, required: true}
  },
  setup(props) {
    const range = useRangeSelection(props.song, props.player);
    const deviceType = useDeviceType();
    const fontSize = computed(() => {
      const size = lerp(deviceType.interpolations.sm.value, 35, 40);
      return `font-size:${size}px`;
    });

    const localStorage = useLocalStorageFlag('song-player-options', true);
    const options = computed(() => {
      return localStorage.get();
    });
    const onToggleOptions = () => {
      localStorage.toggle();
    };
    const optionsLabel = computed(() => (options.value ? 'HIDE OPTIONS' : 'SHOW OPTIONS'));

    return {
      range,
      fontSize,
      options,
      optionsLabel,
      onToggleOptions
    };
  }
};
</script>
