<template>
  <div class="flex flex-col items-end">
    <div class="grid" :style="gridTemplateColumns">
      <div class="h-20 col-start-2 flex justify-between items-end">
        <standard-heading :size="2">Filters</standard-heading>
        <icon-button :size="'xl'" :icon="iconXmark" :on-click="onClose" :tooltip="'Close Menu'" />
      </div>
    </div>
    <div class="pt-fl-2xl grid" :style="gridTemplateColumns">
      <div class="col-start-2">
        <link-button :size="'sm'" :on-click="clearFilters" :label="'Reset All'" />
      </div>
    </div>
    <div v-if="search !== undefined" class="py-fl-xl grid" :style="gridTemplateColumns">
      <div class="col-start-2">
        <search-component :search="search" :placeholder-text="'Search filtered results'" />
      </div>
    </div>
    <div v-else class="pt-fl-md"></div>
    <div v-for="item in items" :key="item.key" class="grid" :style="gridTemplateColumns">
      <div class="col-start-2">
        <filter-set-filter :filter="item.filter" :filter-set="filterSet" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {computed, defineComponent, PropType} from 'vue';
import StandardHeading from '../../core/standard-heading/StandardHeading.vue';
import IconButton from '../../core/button/IconButton.vue';
import LinkButton from '../../core/button/LinkButton.vue';
import FilterSetFilter from '../filter-set-filter/FilterSetFilter.vue';
import SearchComponent from '../search/Search.vue';
import {FilterSetComposition} from '../../vue-composition/filter-set/types';
import {ContentEntrySearchComposition} from '../../vue-composition/search/search';
import {ContentEntrySortComposition} from '../../vue-composition/sort/types';
import {faArrowUpArrowDown} from '@fortawesome/pro-regular-svg-icons/faArrowUpArrowDown';
import {faXmark} from '@fortawesome/pro-light-svg-icons/faXmark';
import {useDeviceType} from '../../vue-composition/device-type/device-type';
import {lerp} from '../../../utils/math';

export default defineComponent({
  components: {
    StandardHeading,
    IconButton,
    LinkButton,
    FilterSetFilter,
    SearchComponent
  },

  props: {
    filterSet: {type: Object as PropType<Readonly<FilterSetComposition>>, required: true},
    sort: {type: Object as PropType<Readonly<ContentEntrySortComposition>>, default: undefined},
    search: {type: Object as PropType<Readonly<ContentEntrySearchComposition>>, default: undefined},
    onClose: {type: Function as PropType<() => void>, required: true}
  },

  setup(props) {
    const deviceType = useDeviceType();

    const clearFilters = () => {
      if (props.filterSet !== undefined) {
        props.filterSet.clearAll();
      }
      if (props.search !== undefined) {
        props.search.clear();
      }
    };

    const iconXmark = computed(() => faXmark);
    const iconUpDown = computed(() => faArrowUpArrowDown);

    const gridTemplateColumns = computed(() => {
      const contentArea = lerp(deviceType.interpolation.value, 230, 325);
      const margin = lerp(deviceType.interpolation.value, 30, 55);
      return `grid-template-columns: minmax(20px,1fr) minmax(0px,${contentArea}px) minmax(10px,${margin}px)`;
    });

    const items = computed(() => {
      const filters = props.filterSet.filters.value;
      return filters.map((f, i) => {
        return {
          key: `filter-menu-${f.name}-${i}`,
          filter: f
        };
      });
    });

    return {
      iconXmark,
      iconUpDown,
      clearFilters,
      close,
      gridTemplateColumns,
      items
    };
  }
});
</script>
