<template>
  <div>
    <page-section :margin-top="marginTop" :margin-bottom="marginBottom">
      <page-section-header :width="'wide'" :heading="'New Courses'">
        <link-button v-if="!hideLink" :size="'lg'" :url="'/courses'" :label="'View All'" />
      </page-section-header>
    </page-section>
    <page-section :margin-top="'sm'">
      <carousel
        :width="'wide'"
        :number-of-slides="courseList.length"
        :card-size="cardSize"
        :card-aspect-ratio="'315:547'"
        :disabled="disabled"
      >
        <template v-for="(course, i) in courseList" :key="course.id" #[`${i}`]>
          <card :url="course.url">
            <template #hero>
              <card-hero-image v-if="course.image !== undefined" :config="course.image" />
              <card-hero-shimmer v-else :aspect="'aspect-[315/547]'" />
            </template>
          </card>
        </template>
      </carousel>
    </page-section>
  </div>
</template>

<script lang="ts">
import {PropType, computed, onMounted, ref} from 'vue';
import PageSection, {PageSectionMargin} from '../../../core/page/PageSection.vue';
import PageSectionHeader from '../../../core/page/PageSectionHeader.vue';
import Carousel from '../../../generic/carousel/Carousel.vue';
import Card from '../../../generic/card/Card.vue';
import CardHeroImage from '../../../generic/card/partials/hero/CardHeroImage.vue';
import CardHeroShimmer from '../../../generic/card/partials/hero/CardHeroShimmer.vue';
import LinkButton from '../../../core/button/LinkButton.vue';
import {
  fetchLatestEntriesInSection,
  getImageConfig
} from '../../../../backend/content/content-entry/content-entry-query-utils';
import {useLoader} from '../../../vue-composition/loader/loader';
import {ContentEntry} from '../../../../backend/content/content-entry/content-entry-types';
import {CarouselCardSize, getCarouselImageWidths} from '../../../generic/carousel/carousel';
import {range} from '../../../../utils/array';

export default {
  components: {
    PageSectionHeader,
    PageSection,
    Carousel,
    Card,
    CardHeroImage,
    CardHeroShimmer,
    LinkButton
  },
  props: {
    marginTop: {type: String as PropType<PageSectionMargin>, default: 'xl'},
    marginBottom: {type: String as PropType<PageSectionMargin>, default: 'none'},
    hideLink: {type: Boolean, default: false},
    cardSize: {type: String as PropType<CarouselCardSize>, default: 'xs'}
  },
  setup(props) {
    const loader = useLoader();
    const courses = ref<ReadonlyArray<ContentEntry>>([]);
    const disabled = ref(true);

    onMounted(async () => {
      loader.setLoading(true);
      courses.value = await fetchLatestEntriesInSection(10, 'courses');
      loader.setLoading(false);
      disabled.value = false;
    });

    const imageWidth = computed(() => {
      return getCarouselImageWidths(props.cardSize);
    });

    const courseList = computed(() => {
      if (courses.value.length === 0) {
        return range(10).map(i => {
          return {
            id: i,
            image: undefined,
            title: undefined,
            url: undefined
          };
        });
      }
      return courses.value
        .map(course => {
          const image = getImageConfig(
            course.imageCollection,
            ['poster'],
            course.title,
            imageWidth.value
          );
          return {
            id: course.id,
            image,
            title: course.title,
            url: course.url
          };
        })
        .filter(course => course.image !== undefined);
    });

    return {
      courseList,
      disabled
    };
  }
};
</script>
