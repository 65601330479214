export function shuffleArrayInPlace(array: Array<unknown>) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
}

/**
 * Move an element in the specified array. NOTE: This mutates the array!
 */
export function moveArrayElement(array: Array<unknown>, fromIndex: number, toIndex: number) {
  // See https://stackoverflow.com/questions/5306680/move-an-array-element-from-one-array-position-to-another
  if (fromIndex >= array.length || toIndex >= array.length) {
    throw new Error('Invalid array index');
  }
  return array.splice(toIndex, 0, array.splice(fromIndex, 1)[0]);
}

/**
 * Push an item into an array, if the item doesn't already exist in the array.
 */
export function pushUnique(array: Array<any>, item: any) {
  if (!array.includes(item)) {
    array.push(item);
  }
}

/**
 * Given an array that contains arrays, return the index (in the parent array)
 * of the child array that is longest.
 */
export function getIndexOfLongest(arrayOfArrays: Readonly<Array<Array<any>>>): number {
  // See https://stackoverflow.com/questions/33577266/find-the-index-of-the-longest-array-in-an-array-of-arrays
  return arrayOfArrays.reduce((maxIndex, el, i, arr) => {
    return el.length > arr[maxIndex].length ? i : maxIndex;
  }, 0);
}

/**
 * Given an array that contains arrays, transpose the implied matrix and
 * return the result. Example:
 *
 *    [
 *      [1, 2, 3, 4],
 *      [5, 6, 7, 8],
 *    ]
 *
 * becomes
 *
 *    [
 *      [1, 5],
 *      [2, 6],
 *      [3, 7],
 *      [4, 8],
 *    ]
 */
export function transpose(arrayOfArrays: Readonly<Array<Array<any>>>) {
  return arrayOfArrays[0].map((_, c) => arrayOfArrays.map((_, r) => arrayOfArrays[r][c]));
}

/**
 * Similar to the `range()` function in Python; given a number`n`,
 * this function returns an array [0, 1, ..., n-1].
 */
export function range(n: number): Array<number> {
  return [...Array(n).keys()];
}

/**
 * Given an array, split it into chunks of equal size and return an array
 * of arrays with the result. Example: Splitting [1,2,3,4,5] into chunks of
 * 2 will return [[1,2],[3,4],[5]].
 */
export function splitIntoChunks<T>(array: ReadonlyArray<T>, chunkSize: number): Array<Array<T>> {
  if (chunkSize <= 0) {
    throw new Error('Chunk size out of range');
  }
  const result: Array<Array<any>> = [];
  for (let i = 0; i < array.length; i += chunkSize) {
    const chunk = array.slice(i, i + chunkSize);
    result.push(chunk);
  }
  return result;
}

// See https://www.30secondsofcode.org/js/s/insertion-index-in-sorted-array/#insertion-index-in-sorted-array
export const insertionIndexBy = (
  arr: Array<any>,
  n: any,
  comparatorFn: (a: any, b: any) => number
) => {
  const index = arr.findIndex(el => comparatorFn(n, el) < 0);
  return index === -1 ? arr.length : index;
};

/**
 * Insert the specified element into the specified sorted array,
 * using the specified comparator function.
 *
 * NOTE: The array is mutated.
 */
export function insertSorted<T>(
  sortedArray: Array<T>,
  element: T,
  comparatorFn: (a: T, b: T) => number
) {
  const idx = insertionIndexBy(sortedArray, element, comparatorFn);
  sortedArray.splice(idx, 0, element);
}
