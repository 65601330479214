<template>
  <div v-if="!disabled">
    <card-grid>
      <test-anchor
        v-for="(ce, index) in pagination.items.value"
        :id="`content-entry-card-${ce.sectionHandle}-${index}`"
        :key="ce.id"
        :data="{slug: ce.slug, title: ce.title}"
      >
        <content-entry-card
          class="flex-1 min-w-card sm:min-w-card-sm mb-4 sm:mb-12"
          :guest="guest"
          :content-entry="ce"
          :completed="completed[ce.id]"
          :progress="progress[ce.id]"
          :duration="duration[ce.id]"
          :unlocked="unlocked[ce.id]"
          :spot-color="spotColor"
          :bookmark="bookmark"
          :card-image-width="cardImageWidth"
          :imgix-params="imgixParams"
        />
      </test-anchor>
    </card-grid>
    <content-column v-if="pagination.more.value" :width="'wide'">
      <standard-button :label="'Show More'" :on-click="pagination.onMore" />
    </content-column>
  </div>
  <div v-else>
    <card-grid>
      <content-entry-card
        v-for="i in disabledCards"
        :id="`content-entry-card-${i}`"
        :key="i"
        :guest="guest"
        :bookmark="bookmark"
        :card-image-width="cardImageWidth"
      />
    </card-grid>
  </div>
</template>

<script lang="ts">
import {computed, defineComponent, PropType} from 'vue';
import {ProgressMap} from '../../../../backend/progress/progress-types';
import {CompletedMap, DurationMap, UnlockedMap} from '../types';
import TestAnchor from '../../../core/test/TestAnchor.vue';
import ContentEntryCard from '../../representations/card/ContentEntryCard.vue';
import CardGrid from '../../../core/compositions/CardGrid.vue';
import ContentColumn from '../../../core/compositions/ContentColumn.vue';
import StandardButton from '../../../core/button/StandardButton.vue';
import {ColorConfig} from '../../../core/color-config';
import {BookmarkComposition} from '../../../vue-composition/bookmark/types';
import {PaginationComposition} from '../../../vue-composition/pagination/pagination';
import {ImgixImageWidth} from '../../../core/imgix-image/types';
import {range} from '../../../../utils/array';

export default defineComponent({
  components: {
    TestAnchor,
    ContentColumn,
    CardGrid,
    ContentEntryCard,
    StandardButton
  },
  props: {
    guest: {type: String, required: true},
    progress: {type: Object as PropType<Readonly<ProgressMap>>, default: () => ({})},
    completed: {type: Object as PropType<Readonly<CompletedMap>>, default: () => ({})},
    unlocked: {type: Object as PropType<Readonly<UnlockedMap>>, default: () => ({})},
    duration: {type: Object as PropType<Readonly<DurationMap>>, default: () => ({})},
    spotColor: {type: Object as PropType<Readonly<ColorConfig> | undefined>, default: undefined},
    bookmark: {type: Object as PropType<Readonly<BookmarkComposition>>, required: true},
    pagination: {type: Object as PropType<Readonly<PaginationComposition>>, required: true},
    disabled: {type: Boolean, required: true},
    cardImageWidth: {type: Object as PropType<Readonly<ImgixImageWidth>>, required: true},
    imgixParams: {type: Object, default: () => ({ar: '16:9', fit: 'crop'})}
  },
  setup() {
    const disabledCards = computed(() => {
      return range(12);
    });
    return {
      disabledCards
    };
  }
});
</script>
